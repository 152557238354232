import axios from '@/libs/axios'
export default class DoctosPv {

    getDocto(query) {
        return axios.get(`/v2/DoctosPv/GetDocto`, { params: query })
    }

    getHeader(doctoPvId, query) {
        return axios.get(`/v2/DoctosPV/GetHeader/${doctoPvId}`, { params: query })
    }

    getArticles(doctoPvId, tipoDocto) {
        return axios.get(`/v2/DoctosPV/GetArticles/${doctoPvId}/${tipoDocto}`)
    }

    getInvoiceBalance(doctoPvId) {
        return axios.get(`/v2/DoctosPV/GetInvoiceBalance/${doctoPvId}`)
    }

    //Devoluciones
    getReturnsByTypeDocto(query) {
        return axios.get(`/v2/DoctosPV/GetReturnsByTypeDocto/${query.doctoPvId}`, { params: query })
    }

    getFoliosTickets(folios) {
        return axios.post(`/v2/DoctosPV/GetFoliosTickets`, folios)
    }

    getDocumentosTimbrados(query) {
        return axios.get(`/v2/DoctosPV/GetDocumentosTimbrados`, { params: query })
    }

    //GetPointOfSaleCharges
    getPointOfSaleCharges(query) {
        return axios.get(`/v2/DoctosPV/GetPointOfSaleCharges`, { params: query })
    }

    exportPointOfSaleCharges(query) {
        const url = query.soloSubtotales ? `/v2/DoctosPV/ExportPointOfSaleChargesSubtotals` : `/v2/DoctosPV/ExportPointOfSaleChargesDetail`
        return axios.get(url, {
            responseType: 'blob',
            params: query
        })
    }
}
